import React from 'react';
import { Modal, Button } from 'antd';

type Props = {
    cancelModalVisible?: boolean;
    onDiscard?: () => void;
    onCancel?: () => void;
};

export const DiscardDescriptionModal = ({ cancelModalVisible, onDiscard, onCancel }: Props) => {
    return (
        <>
            <Modal
                title="Discard Changes"
                visible={cancelModalVisible}
                destroyOnClose
                onCancel={onCancel}
                footer={[
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'flex-end' }}>
                        <Button type="text" onClick={onCancel} style={{ backgroundColor: '#29707A', color: '#fff' }}>
                            Cancel
                        </Button>
                        <Button onClick={onDiscard} style={{ backgroundColor: '#4CDD84', color: 'rgba(0,0,0,.85)' }}>
                            Discard
                        </Button>
                    </div>,
                ]}
            >
                <p>Changes will not be saved. Do you want to proceed?</p>
            </Modal>
        </>
    );
};
