import React from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';

import { ANTD_GRAY } from '../../../../constants';

const NullableBadge = styled(Badge)`
    margin-left: 4px;
    &&& .ant-badge-count {
        background-color: ${ANTD_GRAY[1]};
        color: #8c8c8c;
        border: 1px solid #8c8c8c;
        font-size: 12px;
        font-weight: 400;
        height: 22px;
    }
`;

export default function NullableLabel() {
    return <NullableBadge count="Nullable" />;
}
