export const lineColors = [
    '#4CDD84',
    '#29707A',
    '#3FADBD',
    '#5FBEAC',
    '#2C6E61',
    '#13A865',
    '#FF462D',
    '#FEC1B9',
    '#534E3F',
    '#BDBBA9',
    '#746F6F',
    '#8D8888',
    '#667076',
    '#B6BDC0',
    '#9B1200',
    '#193F38',
    '#222527',
    '#F2F1EE',
    '#A3F4CF',
    '#074127',
];
