import React from 'react';
import { Image, Typography, Button } from 'antd';
import styled from 'styled-components';
// import { ANTD_GRAY } from '../entity/shared/constants';
import { formatNumber } from './formatNumber';

const Container = styled(Button)`
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
    width: 138px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: ${(props) => props.theme.styles['box-shadow']};
    &&:hover {
        box-shadow: ${(props) => props.theme.styles['box-shadow-hover']};
        transition: 0.3s;
        border-left: 2px solid rgb(76 221 132);
    }
    white-space: unset;
    border-radius: 2px;
    transition: 0.3s;
    border-left: 2px solid #fff;
`;

const PlatformLogo = styled(Image)`
    max-height: 25px;
    width: auto;
    object-fit: contain;
    background-color: transparent;
`;

const CountText = styled(Typography.Text)`
    color: #29707a;
    font: 600 15pt / 20px IBM Plex Sans;
`;

const LogoContainer = styled.div``;

const TitleContainer = styled.div``;

const Title = styled(Typography.Title)`
    word-break: break-word;
`;

type Props = {
    logoUrl?: string;
    logoComponent?: React.ReactNode;
    name: string;
    count?: number;
    onClick?: () => void;
};

export const LogoCountCard = ({ logoUrl, logoComponent, name, count, onClick }: Props) => {
    return (
        <Container type="link" onClick={onClick}>
            <LogoContainer style={{ color: '#4CDD84' }}>
                {(logoUrl && <PlatformLogo preview={false} src={logoUrl} alt={name} />) || logoComponent}
            </LogoContainer>
            <TitleContainer>
                <Title
                    ellipsis={{
                        rows: 4,
                    }}
                    level={5}
                >
                    {name}
                </Title>
            </TitleContainer>
            {count !== undefined && <CountText>{formatNumber(count)}</CountText>}
        </Container>
    );
};
