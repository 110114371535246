import { Table } from 'antd';
import styled from 'styled-components';
import { ANTD_GRAY } from '../../constants';

export const StyledTable = styled(Table)`
    overflow: inherit;
    height: inherit;

    &&& .ant-table-cell {
        background-color: #fff;
    }
    &&& .ant-table-thead .ant-table-cell {
        font-weight: 500;
        font-size: 12px;
        color: ${ANTD_GRAY[8]};
    }
    &&
        .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        border: 1px solid ${ANTD_GRAY[4]};
    }
    && .ant-table-thead > tr {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000012;
    }
    && .ant-table-row ant-table-row-level-0 {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000012;
    }
` as typeof Table;
// this above line preserves the Table component's generic-ness
