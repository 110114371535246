import styled from 'styled-components/macro';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
// import { useAppConfig } from '../../useAppConfig';
import { useContext } from 'react';
import './HeaderLinks.css';
import { userContext } from '../../../App';
import { useGetAuthenticatedUser } from '../../useGetAuthenticatedUser';
// import { ANTD_GRAY } from '../../entity/shared/constants';
// import { HOME_PAGE_INGESTION_ID } from '../../onboarding/config/HomePageOnboardingConfig';
// import { useUpdateEducationStepIdsAllowlist } from '../../onboarding/useUpdateEducationStepIdsAllowlist';

const LinkWrapper = styled.span`
    margin-right: 0px;
`;

const LinksWrapper = styled.div<{ areLinksHidden?: boolean }>`
    opacity: 1;
    white-space: nowrap;
    transition: opacity 0.5s;

    ${(props) =>
        props.areLinksHidden &&
        `
        opacity: 0;
        width: 0;
    `}
`;

// const MenuItem = styled(Menu.Item)`
//     font-size: 12px;
//     font-weight: bold;
//     max-width: 240px;
// `;

// const NavTitleContainer = styled.span`
//     display: flex;
//     align-items: center;
//     justify-content: left;
//     padding: 2px;
// `;

// const NavTitleText = styled.span`
//     margin-left: 6px;
// `;

// const NavTitleDescription = styled.div`
//     font-size: 12px;
//     font-weight: normal;
//     color: ${ANTD_GRAY[7]};
// `;

interface Props {
    areLinksHidden?: boolean;
}

export function HeaderLinks(props: Props) {
    const location = useLocation();
    const rootPath = location.pathname;
    let seletedOverviewPath = '';
    let seletedAnalyticsPath = '';
    let seletedGlossaryPath = '';
    let seletedDomainsPath = '';
    let seletedIngestionPath = '';
    let seletedSettingsPath = '';
    console.log('rootPath', rootPath);
    const path = rootPath.split('/').slice(1);
    const selectPath = path.slice(0);
    /* const datadiscovery = sessionStorage.getItem('datadiscoverystate');
    console.log(`domians new ${datadiscovery}`); */
    const plane = useContext(userContext);
    console.log(`in headerlinks.tsx , value of plane :${plane}`);
    const inServicePlane: boolean = plane === 'serviceplane';
    // const isValid: boolean = datadiscovery !== 'serviceplane';
    const inControlPlane: boolean = plane === 'controlplane';
    console.log('in service plane', inServicePlane);
    console.log('in control plane', inControlPlane);

    if (
        rootPath === '/' ||
        selectPath[0] === 'browse' ||
        selectPath[0] === 'search' ||
        selectPath[0] === 'chart' ||
        selectPath[0] === 'dashboard' ||
        selectPath[0] === 'dataset'
    ) {
        seletedOverviewPath = 'overview';
    }
    console.log('seletedOverviewPath', seletedOverviewPath);
    if (rootPath === '/analytics' || selectPath[0] === 'analytics') {
        seletedAnalyticsPath = 'analytics';
    }

    console.log('seletedAnalyticsPath', seletedAnalyticsPath);

    if (
        rootPath === '/glossary' ||
        selectPath[0] === 'glossary' ||
        selectPath[0] === 'glossaryTerm' ||
        selectPath[0] === 'glossaryNode'
    ) {
        seletedGlossaryPath = 'glossary';
    }
    console.log('seletedGlossaryPath', seletedGlossaryPath);

    if (
        rootPath === '/domains' ||
        selectPath[0] === 'domains' ||
        // selectPath[0] === 'dataset' ||
        selectPath[0] === 'domain' ||
        selectPath[0] === 'pipelines' ||
        selectPath[0] === 'tasks' ||
        selectPath[0] === 'container' ||
        selectPath[0] === 'mlModels'
    ) {
        seletedDomainsPath = 'domains';
    }
    console.log('seletedDomainsPath', seletedDomainsPath);

    if (rootPath === '/ingestion' || selectPath[0] === 'ingestion') {
        seletedIngestionPath = 'ingestion';
    }
    console.log('seletedIngestionPath', seletedIngestionPath);

    if (
        rootPath === '/settings' ||
        selectPath[0] === 'settings' ||
        selectPath[0] === 'group' ||
        selectPath[0] === 'user'
    ) {
        seletedSettingsPath = 'settings';
    }
    console.log('seletedSettingsPath', seletedSettingsPath);
    const { areLinksHidden } = props;
    const me = useGetAuthenticatedUser();
    // const { config } = useAppConfig();

    // const isAnalyticsEnabled = config?.analyticsConfig.enabled;
    // const isIngestionEnabled = config?.managedIngestionConfig.enabled;

    // const showAnalytics = (isAnalyticsEnabled && me && me.platformPrivileges.viewAnalytics) || false;
    // const showSettings = true;
    // const showIngestion =
    //     isIngestionEnabled && me && me.platformPrivileges.manageIngestion && me.platformPrivileges.manageSecrets;
    const showDomains = me?.platformPrivileges.createDomains || me?.platformPrivileges.manageDomains;

    // useUpdateEducationStepIdsAllowlist(!!showIngestion, HOME_PAGE_INGESTION_ID);

    return (
        <LinksWrapper areLinksHidden={areLinksHidden}>
            <LinkWrapper>
                <Link to="/">
                    <Button
                        type="text"
                        id="home-tab"
                        className={`button ${seletedOverviewPath === 'overview' ? 'activeButton' : ''}`}
                    >
                        {' '}
                        Overview{' '}
                    </Button>
                </Link>
            </LinkWrapper>
            {inServicePlane && (
                <LinkWrapper>
                    <Link to="/analytics">
                        <Button
                            type="text"
                            id="home-tab"
                            className={`button ${seletedAnalyticsPath === 'analytics' ? 'activeButton' : ''}`}
                        >
                            {' '}
                            Analytics{' '}
                        </Button>
                    </Link>
                </LinkWrapper>
            )}
            {showDomains && (
                <LinkWrapper>
                    <Link to="/domains">
                        <Button
                            type="text"
                            id="home-tab"
                            className={`button ${seletedDomainsPath === 'domains' ? 'activeButton' : ''}`}
                        >
                            {' '}
                            Domains{' '}
                        </Button>
                    </Link>
                </LinkWrapper>
            )}
            <LinkWrapper>
                <Link to="/glossary">
                    <Button
                        type="text"
                        id="home-tab"
                        className={`button ${seletedGlossaryPath === 'glossary' ? 'activeButton' : ''}`}
                    >
                        {' '}
                        Glossary{' '}
                    </Button>
                </Link>
            </LinkWrapper>
            {inControlPlane && (
                <LinkWrapper>
                    <Link to="/ingestion">
                        <Button
                            type="text"
                            id="home-tab"
                            className={`button ${seletedIngestionPath === 'ingestion' ? 'activeButton' : ''}`}
                        >
                            Ingestion{' '}
                        </Button>
                    </Link>
                </LinkWrapper>
            )}
            {inControlPlane && (
                <LinkWrapper style={{ marginRight: 12 }}>
                    <Link to="/settings">
                        <Button
                            type="text"
                            id="home-tab"
                            className={`button ${seletedSettingsPath === 'settings' ? 'activeButton' : ''}`}
                        >
                            {' '}
                            Settings{' '}
                        </Button>
                    </Link>
                </LinkWrapper>
            )}
        </LinksWrapper>
    );
}
