import styled from 'styled-components';

export default styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    height: 46px;
    padding: 7px 16px;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
`;
