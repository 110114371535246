import { Typography, Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DomainsList } from './DomainsList';

const PageContainer = styled.div`
    padding-top: 0px;
`;

const PageHeaderContainer = styled.div`
    && {
        padding-left: 20px;
    }
`;

const PageTitle = styled(Typography.Title)`
    && {
        font-weight: bolder;
        margin-bottom: 0px;
    }
`;

const ListContainer = styled.div``;

export const ManageDomainsPage = () => {
    return (
        <PageContainer>
            <PageHeaderContainer>
                <PageTitle level={3}>Domains</PageTitle>
                <Typography.Paragraph type="secondary">View all domains.</Typography.Paragraph>
                <Divider style={{ marginTop: '-8px' }} />
            </PageHeaderContainer>
            <ListContainer>
                <DomainsList />
            </ListContainer>
        </PageContainer>
    );
};
